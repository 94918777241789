import { A, Outlet } from "@solidjs/router";
import { SignupViewProps } from "../models";
import { splitProps } from "solid-js";
import { Cmp, Ctrl } from ":mods";
import "../styles/nav-top-signup.css";

export function Signup(props: SignupViewProps) {
  const [local, others] = splitProps(props, ["topNav"]);
  // const { id } = Ctrl.Context.hooks.useAppFlavor();
  // const is_mobile = window.matchMedia("only screen and (max-width: 760px)").matches;

  return (
    <section class={`flex flex-col w-full !min-h-full trim$ ${others.class ?? ""}`}>
      <Ctrl.Nav.Top {...local.topNav} />
      <div class="flex flex-col w-full min-h-full text#p bg#paper flow$h trim$w">
        <Outlet />
      </div>
    </section>
  );
}
